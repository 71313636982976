import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import CustomerSupportLayout from './_layout';
import Page from '../../components/Page';
import { Link } from '../../components/Link';

import { Button } from '../../components/Button';

// TODO Refactor
const Label = styled.label`
  height: 21px;
  padding-top: 9px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58rem;
  letter-spacing: 0.3px;
  color: #032e4f;
`;

const StyledInput = styled.input`
  display: block;
  // width: 300px;
  width: 100%;
  // max-width: 500px;
  height: 38px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 4px;
  border: solid 1px ${props => (props.error ? '#dd5353' : '#cfd4dc')};
  background-color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.84;
  letter-spacing: 0.6px;
  color: #032e4f;
`;

export const Input = ({
  title,
  name,
  defaultValue,
  type = 'text',
  required,
  compact = false,
  width,
  ...rest
}) => {
  return (
    <Box pb={compact ? '9px' : '19px'} width={width}>
      <Label htmlFor={name}>
        {title}
        {required ? ' *' : ''}
      </Label>
      <StyledInput
        name={name}
        type={type}
        required={required}
        defaultValue={defaultValue}
        {...rest}
      />
    </Box>
  );
};

const ContactUsPage = props => {
  const { i18n, t } = useTranslation();

  return (
    <CustomerSupportLayout
      subtitle={t('support.contact_us.menu.title')}
      {...props}
    >
      <Page
        title={t('support.contact_us.page.title')}
        subtitle={t('support.contact_us.page.subtitle')}
      >
        <Box mt={[4, 4, '54px']} width={[1, 0.55, 0.45]} mx="auto">
          <form
            id="contact-form"
            name="contactForm"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action={`${
              i18n.language === 'el' ? '' : '/en'
            }/customer_support/contact_us_confirmation`}
          >
            <input type="hidden" name="form-name" value="contactForm" />
            <Input
              title={t('support.contact_us.form.fullname')}
              name="Name"
              required
              compact
            />
            <Input
              title={t('support.contact_us.form.email')}
              name="Email"
              type="email"
              required
              compact
            />
            <Input
              title={t('support.contact_us.form.phone')}
              name="Phone Number"
              type="phone"
              required
              compact
            />
            <Input
              title={t('support.contact_us.form.subject')}
              name="Subject"
              type="text"
              required
              compact
            />
            <Input
              title={t('support.contact_us.form.comments')}
              name="Comments"
              type="text"
              required
              compact
            />
            <Flex pt="30px">
              <input name="Terms of use" type="checkbox" required={true} />
              <Text
                mt="-3px"
                ml={['10px', '20px']}
                pr={['10px', '20px']}
                color="marine"
                fontSize={['12px', '13px', '15px']}
                lineHeight={['20px', '28px']}
                letterSpacing={['0.5px', '0.7px']}
              >
                {t('support.contact_us.form.by_signing_up')}
                <br />
                {t('support.contact_us.form.visit_our_legal.begin')}
                <Link to="/legal-info">
                  {t('support.contact_us.form.visit_our_legal.link')}
                </Link>
                {t('support.contact_us.form.visit_our_legal.end')}
              </Text>
            </Flex>
            <Box pb="30px" textAlign="center">
              <Button mt="30px" mx="auto" px="80px">
                {t('support.contact_us.form.button')}
              </Button>
            </Box>
            <input type="hidden" name="bot-field" />
          </form>
        </Box>
      </Page>
    </CustomerSupportLayout>
  );
};

export default ContactUsPage;
